import React from 'react';
import '../App.css';
import {Helmet} from 'react-helmet';
import HeroSection from '../components/HeroSection';

function Home() {
  return (
    <>
     <Helmet>
                                          <title>Decentrate | Blockchain research and development based in Zurich Switzerland. We specialise in Web 3 infrastructure through sustainable, open-source development.</title>
                                          <meta name = "keywords" content = "blockchain, web3, cryptocurrency, nft, defi, bitcoin, zcash, web 3.0, daaps, development, blockchain development, Parachains, Solidity, Truffle, Ganache, Whisper, Substrate" />
                                          <meta name = "description" content = "Blockchain research and development based in Zurich Switzerland. We specialise in Web 3 infrastructure through sustainable, open-source development." />
                                          <meta name="subject" content = "Blockchain research and development based in Zurich Switzerland. We specialise in Web 3 infrastructure through sustainable, open-source development." />
                                          <meta property="og:title" content="Decentrate | Blockchain research and development" />
                                          <meta property="og:image" content="public/images/decentrate_small_white.jpeg" />
                                      </Helmet>
   
     <HeroSection />
  </>

  );
}

export default Home;