import React from 'react';
import {Helmet} from 'react-helmet';
import './Pages.css';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';
import Footer from '../components/Footer';


function services() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>          
    <div className='our-services'>
                                  <div>   
                                    <Helmet>
                                        <title> Services | Decentrate</title>
                                        <meta name = "keywords" content = "blockchain, decentralization, cryptocurrency, nft, defi, bitcoin, zcash, web3, web 3.0, daaps, development, blockchain development, Parachains, Solidity, Truffle, Ganache, Whisper, Substrate" />
                                          <meta name = "description" content = "Striving to realize the potential of decentralized technology. We build systems that have a lasting and transformative impact." />
                                          <meta name="subject" content = "Striving to realize the potential of decentralized technology. We build systems that have a lasting and transformative impact." />
                                    </Helmet>
                                  </div> 
                                <div className = "header">  <h1>We strive to bring the potential of decentralised technology by building solutions that have a <span className = "text-highlight">lasting</span> and <span className = "text-highlight">transformative</span> impact. </h1> </div>
                                <div className = "header">  <h2>Our goal is to leverage Web 3 infrastructure through sustainable, open-source development and bring the power of <span className = "span-effect"> progressive</span> and <span className = "span-effect"> trustless</span> systems to its greatest potential.</h2></div>
                                <div className = "header">  <h2>Projects we work on range from developing infrastructure for some of the leading public blockchains to decentralised apps, browser wallets, developer tools and scientific research.</h2></div>
                                <div className = "header">  <h2>We work across multiple blockchain ecosystems and currently pioneer Cryptocurrencies, Decentralised Finance (DeFi), Non-Fungible Tokens (NFTs) and Polkadot technology.</h2></div>
                                <div className = "header">  <h2>We are actively pursuing the development of our own product suite.</h2></div>


                                <div className = "service-container">  
                                <div className = "service-container-A"> 
                                        <div className = "content-link">  <h3><span className = "numbers"> 01. </span> Distributed Systems Design</h3></div>
                                        <div className = "content-link">  <h3><span className = "numbers"> 02. </span>  Blockchain Implementation</h3></div>
                                        <div className = "content-link">  <h3><span className = "numbers"> 03. </span>  Private Blockchains</h3></div>
                                        <div className = "content-link">  <h3><span className = "numbers"> 04. </span>  Research and Development</h3></div>
                                </div>
                                <div className = "service-container-C"> 
                                       <div className = "content-link">  <h3><span className = "numbers"> 05. </span>  Tooling Implementation</h3></div>
                                        <div className = "content-link">  <h3><span className = "numbers"> 06. </span>  Smart Contract Engineering</h3></div>
                                      <div className = "content-link">  <h3><span className = "numbers"> 07. </span>  Smart Contract Auditing</h3></div>
                                      <div className = "content-link">  <h3><span className = "numbers"> 08. </span>  dApp Development</h3>
                                   </div>
                               </div>
                               </div>
                                      <div className = "header-item"> 
                                      <div className = "header">  <h2> Allow our experienced research and development team to realise the full potential of your idea.</h2></div>
                  <div class="arrow-container">
                    <Link className = "content-link" to='/contact'> <h3>Contact us
                    <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
              </div>
                                      <div className = "space_bottom"> </div> </div>
                           
          </div> 
        </div>
        <Footer/>

    </div>
  )
}
export default services;