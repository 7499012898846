import React from 'react';
import {Helmet} from 'react-helmet';
import './Pages.css';
import Footer from '../components/Footer';

function CoreValues() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
        <div className='our-corevalues'>
            <div>   
                    <Helmet>
                        <title> Core Values | Decentrate</title>
                        <meta name = "keywords" content = "blockchain, decentralization, cryptocurrency, nft, defi, bitcoin, zcash, web3, web 3.0, zurich, crypto vally, daaps, development, blockchain development, Parachains, Solidity, Truffle, Ganache, Whisper, Substrate" />
                                          <meta name = "description" content = "Supporting the future of open-source software. Bringing the power of trustless systems to its greatest potential." />
                                          <meta name="subject" content = "Delivering high-quality code efficiently in a people-first manner" />
                    </Helmet>
                </div> 
            <div className = "header">  <h1>Core Values  </h1> </div>
            <div className = "header">  <h2>Our aim is to support the future of open-source software and to bring the power of trustless systems to its greatest potential. We pursue this by delivering high-quality code efficiently and in a people-first manner.</h2> </div>
          <div className = "service-container"> 
                  <div className = "service-container-A"> 
                          <div className = "content-link">  <h3>Openness</h3></div>
                            <div className = "service-container-detail"> <p>We welcome different ideas and beliefs without judgment and practice being open and respectful in all communications.</p></div>
                          <div className = "content-link">  <h3>Learning</h3></div>
                            <div className = "service-container-detail"> <p>We relentlessly identify opportunities to improve on professional and personal level, while working on nasent technology.</p></div>
                          <div className = "content-link">  <h3>Collaboration</h3></div>
                            <div className = "service-container-detail"> <p>We believe that we can achieve more as a team than we ever could individually and are eager to have regular constructive feedbacks in order to improve.</p></div>
                            </div>
                  <div className = "service-container-B"> 
                          <div className = "content-link">  <h3>Compassion</h3></div>
                          <div className = "service-container-detail"> <p>We recognize each individual's humanity, treat everyone with love and kindness and go above and beyond in helping everyone feel at home.</p></div>
                          <div className = "content-link">  <h3>Accountability</h3></div>
                          <div className = "service-container-detail"> <p>We follow through on duties, responsibilities and commitments in a timely fashion and admit mistakes even if no one else sees them while taking ownership for any consequences of our decisions.</p></div>
                          <div className = "content-link">  <h3>Diligence</h3></div>
                          <div className = "service-container-detail"> <p>We hold ourselves and our colleagues accountable to a high standard and look for the long-term solution instead of providing a short-term fix.</p></div>
                  </div>
                  <div className = "service-container-C"> 
                        <div className = "content-link">  <h3>Friendliness</h3></div>
                        <div className = "service-container-detail"> <p>We inject our personality and random fun things into ordinary routines and don't take things too seriously even during stressful situations.</p></div>
                        <div className = "content-link">  <h3>Freedom</h3></div>
                        <div className = "service-container-detail"> <p>We respect autonomy and independence and encourage others to express themselves freely. We choose a schedule and location that fits with our lifestyles.</p></div>
                  </div>
            </div>
            <div className = "space_bottom"> </div> 

    </div> 
          </div>
          <Footer/>
        </div>
  )
}
export default CoreValues;