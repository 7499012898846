import React from 'react';
import {Helmet} from 'react-helmet';
import './Pages.css';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';
import Footer from '../components/Footer';

function Careers() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
    <div className='contactus'> 
        <div>   
            <Helmet>
              <title> Careers | Decentrate</title>
            </Helmet>
        </div> 
    <div className = "header">  <h1>At Decentrate, we are a compassionate group of builders and makers, working to bring decentralized ideas to life. </h1> </div>
    <div className = "header">  <h2>You will be part of a team that believes in the importance of community and contributing to advance humanity with open-source and blockchain technology.</h2> </div>
    <div className = "header">  <h2>We are global, remote-friendly and open to contractors. For those local to Zurich, our casual office is in Altstetten, few minutes away from Crypto Valley. </h2> </div>
    <div className = "header">  <h2>We promote an Agile environment where tasks are assigned based not just on what you know but what you desire to learn. </h2> </div>
    <div className = "header">  <h2> We offer fast opportunities for personal growth, mentorship, professional development funding, lots of autonomy and responsibility, a results-driven environment, close collaboration with an international team, exposure to a wide array of ecosystems and next-generation technologies and flexible work hours.</h2> </div>
    <div className = "header-item"> 
          <div class="arrow-container">
                  <Link className = "content-link" to='/CoreValues'><h3>Our Core Values 
                    <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link>  
                    </div>
                    <div class="arrow-container">
                        <Link className = "content-link" to='/golangdeveloper'><h3>Golang Developer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>
                          <div class="arrow-container">
                          <Link className = "content-link" to='/rustdeveloper'><h3>Rust Developer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>
                          <div class="arrow-container">
                          <Link className = "content-link" to='/blockchainsolutionsengineer'><h3>Blockchain Solutions Engineer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>
                          </div>
                  <div className = "space_bottom"> </div>
    </div>
      </div>
      <Footer/>
    </div>
)
}
export default Careers;