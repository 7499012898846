import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from '../components/Footer';
import '../pages/Pages.css';

function Careers() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
    <div className='contactus'> 
        <div>   
            <Helmet>
              <title> Blockchain Solutions Engineer | Decentrate</title>
            </Helmet>
        </div> 
    <div className = "header"> <h1>Blockchain Solutions Engineer</h1> </div>
    <div className = "header"> <h2>Who we need</h2> </div>
      <div className = "header"> <p>The ideal candidate would be a software developer with a deep passion for research and development, a math background, and blockchain experience.</p> </div>
    <div className = "header">  <h2>Who you are</h2> </div>
      <div className = "header"> <p>You are passionate about decentralization and blockchain. You like solving complex problems and do not give up until a solution is found. You value practicality over perfection. You like learning new things and explaining them to people. You are good at reviewing and writing long reserach documents.This role will provide an opportunity to be up-to-date with the latest innovations around blockchain technology. The right candidate would be motivated to be both an observer and a driver of these innovations. You will have the opportunity to work on a variety of projects. Every project is on the cutting-edge and quite often we're figuring things out as we go, so continuous learning and teaching is the norm.</p> </div>
      <div className = "header"> <h2>Responsibilites</h2> </div>
      <div className = "bullets"> <p> 
              <ul>
                  <li>Investigate and estimate new development projects and product integrations</li>
                  <li>Figure out the best way to implement new features for company products and write specifications for them</li>
                  <li>Discuss technical details of projects with clients and codify requirements, deliverables and milestones</li>
                  <li>Write implementation guidelines and review implementations</li>
                  <li>Write implementation guidelines and review implementations</li>
                  <li>Assess the state of software dependencies</li>
                  <li>Communicate implementation requirements and explain ambiguities to developers</li>
              </ul>
            </p> </div>
      <div className = "header">  <h2>Requirements</h2> </div>
      <div className = "bullets">  <p> 
              <ul>
                  <li>Familiar with the entire blockchain stack including front end, back end, smart contracts and protocol layers</li>
                  <li>Experience building blockchain protocols or applications</li>
                  <li>Ability to break down complicated concepts into simple language </li>
                  <li>Deep understanding of cryptography and security in the context of blockchain</li>
                  <li>Comfortable reviewing code in the absence of documentation</li>
                  <li>Some experience with Go, Rust, JavaScript, and/or Solidity</li>
                  <li>Understanding of distributed systems and peer-to-peer network protocols</li>
              </ul>
            </p> </div>
            <div className = "header">  <h2>Bonus Points</h2> </div>
      <div className = "bullets">  <p> 
              <ul>
                  <li>Experience writing white papers, technical articles, thesis, etc</li>
                  <li>Experience with open-source development</li>
                  <li>Experience writing articles or blog posts</li>
                  <li>Product or business development experience</li>
                  <li>Comfortable reviewing code in the absence of documentation</li>
                  <li>Some experience with Go, Rust, JavaScript, and/or Solidity</li>
                  <li>Understanding of distributed systems and peer-to-peer network protocols</li>
              </ul>
            </p> </div>
            <div className = "header">  <h2>Sound like a good fit for you?</h2> </div>
            <div className = "header">  <p>Please email careers(at)decentrate.com.</p> </div>
    </div>
    <div className = "space_bottom"> </div>

      </div>
      <Footer/>
    </div>
)
}
export default Careers;