import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer () {
  return (
    <>
          <div className = 'footer-bottom-sticky'>
          <div className='footer-container-mobile'>
              <Link className='footer-column-mobile' to='/'> <p>Home</p> </Link>
              <Link className='footer-column-mobile' to='/services'> <p>Services</p> </Link>
              <Link className='footer-column-mobile' to={{ pathname: 'https://medium.com/@decentrate'}}  target="_blank"><p>News</p> </Link>
              <Link className='footer-column-mobile' to= '/careers'> <p>Careers</p> </Link>
              <Link className='footer-column-mobile' to= '/corevalues'> <p>Core Values</p> </Link>
              <Link className='footer-column-mobile' to= '/contact'> <p>Contact</p> </Link>
                <div className='footer-container-logo'>
                <Link className='footer-logo' to='/'>
                    <img className ='footer-logo' src='/images/decentrate_small_white.jpeg'/> </Link>  
                    </div> 
                    <Link className='footer-column-mobile'to= '/'> <p>Decentrate   &copy; {new Date().getFullYear()} </p></Link> 
            <Link className='footer-column-mobile' to={{ pathname: 'https://goo.gl/maps/85mZAikxT7Z5gyLNA'}} target="_blank"> <p>Zurich, Switzerland</p> </Link>
               
                <div className='footer-container'>
                        <Link className='footer-column'to= '/'> <p>Decentrate   &copy; {new Date().getFullYear()} </p></Link> 
                        <Link className='footer-column' to={{ pathname: "mailto: info@decentrate.com" }} target="_blank"> <p>info@decentrate.com</p> </Link>
                        <Link className='footer-column' to={{ pathname: 'https://goo.gl/maps/85mZAikxT7Z5gyLNA'}} target="_blank"> <p>Zurich, Switzerland</p> </Link>
                        <Link className='footer-column' to= '/corevalues'> <p>Core Values</p> </Link>
                        </div>   
                    <div class='social-media-wrap'>
                          <div className='footer-social-icons'> 
                            <Link className='social-icon-link email' to={{ pathname: 'mailto: info@decentrate.com'}} target="_blank" aria-label='Email'> <i class='fas fa-envelope' /> </Link>
                            <Link className='social-icon-link twitter' to={{ pathname: 'https://twitter.com/decentratehq'}} target="_blank" aria-label='Twitter'> <i class='fab fa-twitter' /> </Link>
                            <Link className='social-icon-link medium' to={{ pathname: 'https://medium.com/@decentrate'}} target="_blank" aria-label='Medium'> <i class='fab fa-medium-m' /> </Link>
                            <Link className='social-icon-link github' to={{ pathname: 'https://www.linkedin.com/company/72575642/'}} target="_blank" aria-label='Linkedin'> <i class='fab fa-linkedin' /> </Link>
                            <Link className='social-icon-link github' to={{ pathname: 'https://github.com/decentrate'}} target="_blank" aria-label='Github'> <i class='fab fa-github' /> </Link>
                          </div> 
           
          </div>
      </div>
      </div>
      </>
    )
}
export default Footer;