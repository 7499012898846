import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { NavLink } from 'react-router-dom';
function Navbar() {
const [click, setClick] = useState(false);
const handleClick = () => setClick(!click);
const closeMobileMenu = () => setClick(false);
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img className ='resize' src='/images/decentrate_logo.png'/>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <NavLink exact activeClassName = "active" to='/services' 
              className='nav-links' 
              onClick={closeMobileMenu}>
                Services
              </NavLink>
            </li>
            <li className='nav-item'>
              <NavLink exact activeClassName = "active"
                to='/careers'
                className='nav-links'
                onClick={closeMobileMenu}>
                Careers
              </NavLink>
            </li>

            <li className='nav-item'>
              <NavLink exact activeClassName = "active"
                to={{ pathname: 'https://medium.com/@decentrate'}} target="_blank"
                className='nav-links'
                onClick={closeMobileMenu}>
                News
              </NavLink>
            </li>
            
            <li className='nav-item'>
              <NavLink exact activeClassName = "active"
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}>
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}
export default Navbar;