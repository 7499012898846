import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from '../components/Footer';
import '../pages/Pages.css';

function Careers() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
    <div className='contactus'> 
        <div>   
            <Helmet>
              <title> Rust Developer | Decentrate</title>
            </Helmet>
        </div> 
    <div className = "header"> <h1>Rust Developer</h1> </div>
    <div className = "header"> <h2>Who we need</h2> </div>
      <div className = "header"> <p>We are looking for a passionate and proactive Rust Developer who shares our commitment to building out open source software and pushing blockchain into mainstream adoption. You will work alongside a core team on cutting-edge technology and projects. You will contribute to your team's success through consistent, high-quality code and jumping in wherever you are needed.
</p> </div>
    <div className = "header">  <h2>Who you are</h2> </div>
      <div className = "header"> <p>You have a genuine enthusiasm for the capabilities and performance of Rust, and using Rust cryptography libraries. You have at least one year of building experience with Rust and are familiar with Filecoin, IPFS, Polkadot, or Substrate. With solid examples of your work and involvement in the blockchain community through Discord, Slack, blogging, or virtual meetups, you have the ability to describe core features of the language such as concurrency and memory management.
</p> </div>
      <div className = "header"> <h2>Responsibilites</h2> </div>
      <div className = "header"> <p> You have solid hands-on experience as a Software Developer, most recently building and developing with Rust. You have a practical understanding of its paradigms, constructs, and idioms. You have a concrete understanding of distributed systems, computer networking, core blockchain concepts and a basic understanding of cryptography and common applications.
</p> </div> 
            <div className = "header">  <h2>Sound like a good fit for you?</h2> </div>
            <div className = "header">  <p>Please email careers(at)decentrate.com.</p> </div>
      <div className = "space_bottom"> </div>
    </div>
      </div>
      <Footer/>
    </div>
)
}
export default Careers;