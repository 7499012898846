import React from 'react';
import {Helmet} from 'react-helmet';
import './Pages.css';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';
import Footer from '../components/Footer';
 
function ContactUs() {
  return (
    
    <div className ='page-container'>
    <div className = 'content-wrap'>
                  <div className='contactus'> 
                    <div>   
                        <Helmet>
                          <title> Contact | Decentrate</title>
                          <meta name = "keywords" content = "blockchain, decentralization, cryptocurrency, nft, defi, bitcoin, zcash, web3, web 3.0, zurich, crypto vally, daaps, development, blockchain development, Parachains, Solidity, Truffle, Ganache, Whisper, Substrate" />
                                          <meta name = "description" content = "Genuinely dedicated to achieve your success and push mainstream adoption of blockchain." />
                                          <meta name="subject" content = "Genuinely dedicated to achieve your success and push mainstream adoption of blockchain." />
                        </Helmet>
                    </div> 
                <div className = "header"> <h1>We are <span className = "text-highlight">genuinely dedicated</span> to achieve your success and to push mainstream adoption of blockchain. </h1> </div> 
                <div className = "header">  <h2>Decentrate offers services across every major blockchain space. We strive to bring the power of trustless systems to its greatest potential. We do this by delivering high-quality code efficiently and doing that in a people-first manner.</h2></div>
                <div className = "header"> <h2>We are a team of consultants and software engineers with diverse technical backgrounds and skill sets. Our office is in Altstetten, Zurich few minutes away from Crypto Valley. </h2></div>

                  <div className = "header-item"> 
                  <div class="arrow-container">
                  <Link className = "content-link" to='/CoreValues'><h3>Our Core Values 
                    <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link>  
                    </div>
                  <div class="arrow-container">
                  <Link className = "content-link" to={{ pathname: 'mailto: info@decentrate.com'}}
                  target="_blank"><h3>Get in Touch<span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
              </div>
                                <div className = "space_bottom"> </div>
                  </div>
                </div>
                </div>
                <Footer/>
    </div>
)
}
export default ContactUs;