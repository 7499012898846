import React from 'react';
import {Helmet} from 'react-helmet';
import '../pages/Pages.css';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';
import Footer from '../components/Footer';

function OpenPositions() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
   <div className='openpositions'> 
                        <div>   
                            <Helmet>
                              <title> Open Positions | Decentrate</title>
                            </Helmet>
                        </div>  
                <div className = "header">  <h1>Decentrate is a unique company in a unique space, growing quickly and building a global reputation in this nascent space.</h1>  </div> 
                <div className = "header">  <h2>Beyond offering experience across every major blockchain space to our team, we provide an open, inclusive, and supportive environment where we help folks reach their full potential as a human and as a professional.</h2>  </div> 

                  <div className = "header-item">
                        <div class="arrow-container">
                        <Link className = "content-link" to='/golangdeveloper'><h3>Golang Developer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>

                          <div class="arrow-container">
                          <Link className = "content-link" to='/rustdeveloper'><h3>Rust Developer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>

                          <div class="arrow-container">
                          <Link className = "content-link" to='/blockchainsolutionsengineer'><h3>Blockchain Solutions Engineer
                          <span className = "icon-container"> <TiArrowRight/> </span> </h3> </Link> 
                          </div>
                  </div>
                  <div className = "space_bottom_jobs"> </div>
                </div>
            </div>
                <Footer/>
    </div>
    
)
}
export default OpenPositions;