import React, { Component } from 'react';
import '../App.css';
import {Helmet} from 'react-helmet';
import { Button } from './Button';
import '../pages/Pages.css';
import Footer from '../components/Footer';
const textArray = ['Aleo.', 'zk-SNARKs.', 'Blake3.', 'Zebra.'];
class HeroSection extends Component { 
  constructor(props){ 
    super(props) 
    this.state = {textIdx : 0} 
    this.makeTimer() 
  }  
  makeTimer(){ 
    setInterval(() => { 
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
        }, 2000) 
  } 
  render(){ 
    let textThatChanges = textArray[this.state.textIdx % textArray.length];
              return (
                <div className ='page-container'> 
                <div className = 'content-wrap'>
                        <div className='hero-container'> 
                                      <div>   
                                        <Helmet>
                                          <title>Decentrate | Blockchain Research and Development</title>
                                          <meta name = "title" content = "Decentrate | Blockchain Research and Development" />
                                          <meta name = "keywords" content = "blockchain, web3, cryptocurrency, nft, defi, bitcoin, zcash, web 3.0, daaps, development, blockchain development, Parachains, Solidity, Truffle, Ganache, Whisper, Substrate" />
                                          <meta name = "description" content = "Blockchain research and development based in Zurich Switzerland." />
                                          <meta name="subject" content = "Blockchain research and development based in Zurich Switzerland. We specialise in sustainable and open-source development." />
                                      </Helmet>
                                    </div> 
                                  <div className = "header">  <h1><span className = "text-highlight"> Decentrate</span> is a blockchain research and development firm based in Zurich. We specialise in Web 3 and Privacy infrastructure through sustainable, <span className = "text-highlight">open-source development</span>.</h1> </div>
                                    <div className = "sub-header">  <h2>We work with industry leaders and projects that are pushing mainstream adoption of blockchain using <span className = "span-effect">{textThatChanges}</span></h2></div>
                                      <div className='hero-btns'>
                                        <Button
                                          className='btns'
                                          buttonStyle='btn--outline'
                                          buttonSize='btn--large'>
                                          LEARN MORE
                                        </Button>
                                        <div className = "header">  <h1></h1> </div>
                                        <div className='space_bottom'></div>
                                      </div>
                                  </div>
                              </div> 
                      <Footer/>
                    </div>
              );
          } 
}
export default HeroSection;