import React,  {Component } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact'; 
import Careers from './pages/Careers'; 
import CoreValues from './pages/CoreValues'; 
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OpenPositions from './pages/OpenPositions';
import blockchainsolutionsengineer from './openpositions/blockchainsolutionsengineer';
import golangdeveloper from './openpositions/golangdeveloper';
import rustdeveloper from './openpositions/rustdeveloper';
import ScrollToTop from './components/ScrollToTop';

class App extends Component {
      render() {
      return (
        <div className ='app'>
            <Router>
            <ScrollToTop/>
                <Navbar/>
                   <Route render={({location}) => (
                        <TransitionGroup>
                        <CSSTransition
                        key={location.key}
                        timeout={450}
                        classNames="fade">
                            <Switch location = {location}> 
                              <Route path='/' exact component={Home}/> 
                              <Route path='/services' component={Services} />
                              <Route path='/careers' component={Careers} />
                              <Route path='/CoreValues' component={CoreValues} />
                              <Route path='/openpositions' component={OpenPositions} />
                              <Route path='/rustdeveloper' component={rustdeveloper} />
                              <Route path='/golangdeveloper' component={golangdeveloper} />
                              <Route path='/blockchainsolutionsengineer' component={blockchainsolutionsengineer}/>
                              <Route path='/contact' component={Contact} />
                            </Switch>
                        </CSSTransition>
                        </TransitionGroup> )} />
                  </Router>
            </div>     
      );
    }    
 }
export default App;