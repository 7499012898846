import React from 'react';
import {Helmet} from 'react-helmet';
import Footer from '../components/Footer';
import '../pages/Pages.css';

function Careers() {
  return (
    <div className ='page-container'>
    <div className = 'content-wrap'>
    <div className='contactus'> 
        <div>   
            <Helmet>
              <title> Golang Developer | Decentrate</title>
            </Helmet>
        </div> 
    <div className = "header"> <h1>Golang Developer</h1> </div>
    <div className = "header"> <h2>Who we need</h2> </div>
      <div className = "header"> <p>We are looking for a trustworthy and proactive Golang Developer with experience building complex systems. Bonus points for experience in blockchain or distributed systems. As a Developer at Decentrate, you can expect to work on cutting-edge technology and projects, formulate and execute strategies, and coordinate with other team members while taking a lot of initiative and ownership with minimal direction. You will be expected to contribute to your team's success through consistent, high-quality code and have an appetite for learning new things.</p> </div>
      <div className = "header"> <h2>Responsibilites</h2> </div>
      <div className = "bullets"> <p> 
              <ul>
                  <li>Identify, prioritize and execute tasks in the software development life cycle</li>
                  <li>Write scalable, robust, testable, efficient, and easily maintainable code</li>
                  <li>Play a key role in architectural and design decisions</li>
                  <li>Mentor other developers and do active code reviews</li>
                  <li>Automate tasks through appropriate tools and scripts</li>
                  <li>Assess the state of software dependencies</li>
                  <li>Collaborate with internal teams and vendors to fix and improve products</li>
              </ul>
            </p> </div>
      <div className = "header">  <h2>Requirements</h2> </div>
      <div className = "bullets">  <p> 
              <ul>
                  <li>Intermediate knowledge of Go programming language, paradigms, constructs, and idioms</li>
                  <li>3+ years professional software development experience</li>
                  <li>Excellent communication skills</li>
                  <li>Effective usage of Golang debugging tools</li>
                  <li>Basic understanding of cryptography and common applications</li>
                  <li>Ability to write clean and effective documentation and comments</li>
                  <li>Availability of 35+hrs/week</li>
              </ul>
            </p> </div>
            <div className = "header">  <h2>Bonus Points</h2> </div>
      <div className = "bullets">  <p> 
              <ul>
                  <li>Concrete understanding of core blockchain concepts and building distributed systems or applications</li>
                  <li>Familiar with Git and GitHub/GitLab</li>
                  <li>Can construct and maintain CI/CD pipelines</li>
                  <li>Experience with RPC, WebSockets, or GraphQL</li>
                  <li>Experience with Rust, JavaScript/Typescript, or Solidity</li>
                  <li>Contributions to Web3.0 projects</li>
                  <li>Experience working with Docker and/or Kubernetes
</li>
              </ul>
            </p> </div>
            <div className = "header">  <h2>Sound like a good fit for you?</h2> </div>
            <div className = "header">  <p>Please email careers(at)decentrate.com.</p> </div>
      <div className = "space_bottom"> </div>
    </div>
      </div>
      <Footer/>
    </div>
)
}
export default Careers;